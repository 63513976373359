import Header from "./components/Header";

import Footer from "./components/Footer/Footer"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import routes from "./router/routes";

function App() {

  return (
    <>
      <Router>
        <Header />
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={route.element}
              />
            );
          })}
        </Routes>
        <Footer />
      </Router>

    </>

  )

}

export default App;


